:root {
  --amplify-primary-color: #ff7050;
  --amplify-primary-tint: #ffa090;
  --amplify-primary-shade: #ff6040;
  --amplify-primary-contrast: #ffffff;
  --amplify-secondary-color: #70a050;
  --amplify-secondary-tint: #a0a090;
  --amplify-secondary-shade: #60a040;
  --main-right: #f0fff8;
}

:root, [data-amplify-theme] {
  --amplify-fonts-default-variable: 'Amazon Ember', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !important;
}

.App-header {
  background-color: #f5ede4;
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 2.5vmin;
  color: black;
  position: fixed;
  z-index: 1;
}

.App-header-es {
  background-color: #e0edf8 !important;
}

.App-logo {
  width: 80px;
  height: 34px;
  margin-left: 12px;
  margin-right: 20px;
}

.App-change-password {
  text-align: end;
  flex: 1;
  padding-right: 10px;
}

.login-title {
  font-weight: bolder;
  font-size: larger;
}

.login-footer {
  margin-top: 15px;
  font-size: smaller;
}

.sign-in {
  margin-top: 30px;
  margin-left: 30px;
}

.sign-out {
  background-color: var(--amplify-primary-color);
  color: var(--amplify-primary-contrast);
  border: 0px;
  padding: 15px;
  min-width: 120px;
  font-size: smaller;
}

.sign-out:hover {
  background-color: var(--amplify-primary-tint);
}

.App-main {
  display: flex;
  flex-direction: row;
  /* align-items: left; */
  justify-content: left;
}

.App-main-left {
  background-color: #f8f8ff;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 16vw;
  height: 100vh;
  position: fixed;
}

.App-main-menu {
  padding-top: 50px;
}

.App-main-query {
  font-size: small;
  text-align: center;
}

.App-main-right {
  background-color: var(--main-right);
  padding: 3px;
  padding-left: 15px;
  min-width: calc(100% - 16vw);
  min-height: calc(100vh - 50px);
  position: relative;
  top: 50px;
  left: 16vw;
}

.App-main-right input, .App-main-right button, .App-main-right textarea, .App-main-right select {
  font-size: 10pt;
}

.App-link {
  color: #61dafb;
}

.answer-notice {
  background-color: #ffe8e0;
  white-space: pre-line;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 3px;
  border-radius: 5px;
}

.answer-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.answer-header {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 3px;
  border-radius: 5px;
}

.answer-description {
  font-weight: bold;
  white-space: pre-line;
}

.answer-radio {
  background-color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 3px;
  border-style: solid;
  border-color: #61dafb;
  border-radius: 5px;
  border-width: 3px;
}

.answer-radio-title {
  font-weight: bold;
}

.answer-textarea {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 3px;
  border-radius: 5px;
}

.answer-scroll {
  position: relative;
  top: -50px;
}

.answer-depth-1 {
  background-color: #d8fff4;
  border-style: solid;
  border-color: #51d6fa;
  border-radius: 1px;
  border-width: 2px;
}

.answer-depth-2 {
  background-color: #c0ffe8;
}

.answer-depth-3 {
  background-color: #a8ffdc;
}

.answer-submit {
  background-color: var(--amplify-primary-color);
  color: var(--amplify-primary-contrast);
  border: 0px;
  padding: 10px;
}

.answer-submit:hover {
  background-color: var(--amplify-primary-tint);
}

.answer-submit:active {
  background-color: var(--amplify-primary-shade);
}

.answer-necessity {
  background-color: #ffeeee;
}

.edit-modes button {
  margin-right: 30px;
}

.edit-buttons img {
  margin-left: 3px;
  cursor: pointer;
}

.edit-buttons {
  white-space: nowrap;
}

.admin-update {
  background-color: var(--amplify-primary-color);
  color: var(--amplify-primary-contrast);
  border: 0px;
  padding: 10px;
}

.admin-misc-button {
  background-color: #ff6030;
  color: var(--amplify-primary-contrast);
  border: 0px;
  border-radius: 3px;
  padding: 3px;
}

.admin-update:hover, .admin-misc-button:hover {
  background-color: var(--amplify-primary-tint);
}

.admin-update:active, .admin-misc-button:active {
  background-color: var(--amplify-primary-shade);
}

.admin-update:disabled {
  background-color: #ffd0b0;
}

.admin-misc-button:hover {
  background-color: var(--amplify-primary-tint);
}

.admin-misc-button:active {
  background-color: var(--amplify-primary-shade);
}

.admin-table-outer {
  overflow: auto;
  min-height: 70vh;
  max-height: 70vh;
}

.admin-table {
  border-collapse: collapse;
}

.admin-table tr:first-child {
  position: sticky;
  top: 0;
  background-color: var(--main-right);
  z-index: 1;
}

.admin-table tr:first-child th, .admin-table tr:first-child td {
  border-bottom: solid 2px #999999;
  white-space: nowrap;
}

.admin-table tr:last-child th, .admin-table tr:last-child td {
  border-bottom: 0px;
}

.admin-table tr th, .admin-table tr td {
  border-bottom: solid 1px #999999;
  text-align: left;
  padding: 2px 6px;
  height: 40px;
}

.admin-line-button {
  background-color: var(--amplify-primary-color);
  color: var(--amplify-primary-contrast);
  border: 0px;
  padding: 4px 6px;
}

.admin-line-button:hover {
  background-color: var(--amplify-primary-tint);
}

.admin-line-button:active {
  background-color: var(--amplify-primary-shade);
}

.admin-line-button:disabled {
  background-color: var(--amplify-primary-contrast);
}

.admin-line-button-secondary {
  background-color: var(--amplify-secondary-color);
  color: var(--amplify-secondary-contrast);
  border: 0px;
  padding: 4px 6px;
}

.admin-line-button-secondary:hover {
  background-color: var(--amplify-secondary-tint);
}

.admin-line-button-secondary:active {
  background-color: var(--amplify-secondary-shade);
}

.admin-line-button-secondary:disabled {
  background-color: var(--amplify-primary-contrast);
}

.admin-users-sorter {
  width: 8rem;
}

.admin-long-input {
  width: 90%;
}

.admin-table-input {
  width: 100%;
}

.admin-enquete-updating-message {
  height: 1rem;
}

.admin-not-answered-table {
  border-collapse: collapse;
  margin-top: 10px;
}

.admin-not-answered-table tr th, .admin-not-answered-table tr td {
  text-align: left;
  white-space: pre-line;
  border-top: solid 1px #999999;
  border-bottom: solid 1px #999999;
}

.admin-answers-table {
  border-collapse: collapse;
  margin-top: 10px;
}

.admin-answers-table tr th, .admin-answers-table tr td {
  text-align: left;
  white-space: pre-line;
  border-bottom: solid 1px #999999;
}

.admin-answers-table tr:first-child th, .admin-answers-table tr:first-child td {
  border-top: solid 2px #333333;
}

.admin-answers-deleted-table {
  background-color: #eeeeee;
}

.admin-notice {
  white-space: pre-line;
}

.admin-move {
  white-space: nowrap;
}

.admin-popup-button {
  background-color: var(--amplify-primary-color);
  color: var(--amplify-primary-contrast);
  border: 0px;
  min-height: 1.8em;
  width: 7em;
  margin-top: 10px;
  margin-right: 10px;
}

.answer-file-upload {
  width: 600px;
  height: 24px;
  border: 1px dotted #888;
}

.user-file-download {
  display: inline-block;
  vertical-align: top;
}

.user-file-upload-container {
  display: inline-block;
  margin-left: 10px;
}

.user-file-upload {
  width: 500px;
  height: 28px;
  border: 1px dotted #888;
}

.edit-table {
  table-layout: fixed;
  width: 100%;
}

.edit-table tr {
  margin-bottom: 5px;
}

.edit-table th {
  width: 5em;
  vertical-align: top;
}

.edit-table-icon {
  position: relative;
  top: 2px;
}

.edit-popup-text {
  width: 95%;
}

.confirmation-warn {
  font-weight: bold;
  color: red;
}

/* react-datetime */
.rdt .form-control {
  width: 8rem;
}

.bold {
  font-weight: bold;
}

.color-red {
  color: red;
}

.pre-wrap {
  white-space: pre-wrap;
}

.display-none {
  display: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

[data-amplify-container] {
  padding-top: 50px;
  margin: 0 auto;
}
